import React, { Suspense, ComponentType, useState, useMemo, lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Button, Spinner, Panel } from "@xometry/xometry_loft";
import { loadRemoteComponent } from "../../../utils/lazyLoad";
import {
  loadingStyles,
  centeredContent,
  wrapper,
  orderMessageBase,
  orderMessageText,
  orderMessageLink,
} from "./RecentOrders.style";
import { origins, publicUrl } from "../../../utils/constants";
import { FilterData } from "../../../utils/useBrowserHistory";

const RecentOrdersReload = ({ handleReload }: { handleReload: () => void }) => (
  <Panel kind="primary" css={wrapper}>
    <div css={orderMessageBase}>
      <img
        width="228"
        height="165"
        src={`${publicUrl}/assets/images/spot-internal-server-error.svg`}
        alt="Server Error Illustration"
      />
      <div css={orderMessageText}>
        We&apos;re having trouble loading your orders.
      </div>
      <Button css={orderMessageLink} kind="text-blue" onClick={handleReload}>
        Click to try again
      </Button>
    </div>
  </Panel>
);

type RemoteRecentOrdersProps = {
  apiRoot: string;
  wwwRoot: string;
  getRoot: string;
  tableConfig: {
    orderColumns: OrderColumn[];
    ordersPerPage?: number;
    tableName?: string;
    showSearchBox?: boolean;
    showStatusFilter?: boolean;
    showCopyOrderBtn?: boolean;
    showDownloadBtn?: boolean;
    savePageHistory?: boolean;
    showViewAllOrdersLink?: boolean;
  };
  updateUrlWithFilterData?: ((data: FilterData) => void) | undefined;
  queryParams?: FilterData | undefined;
  setErrorMessage?: (b: boolean) => void;
  isTeamView?: boolean;
};

// eslint-disable-next-line no-shadow
enum OrderColumn {
  ORDER_NUMBER = "Order Number",
  QUOTE_NUMBER = "Quote Number",
  PURCHASE_ORDER = "Purchase Order",
  ORDERED_ON = "Ordered On",
  DUE_DATE = "Due Date",
  ORDER_STATUS = "Order Status",
  QUOTED_BY = "Quoted By",
  ORDERED_BY = "Ordered By",
  ORDER_TOTAL = "Order Total",
}

const getLazyRecentOrdersComponent = () =>
  lazy(() => {
    type ModuleType = { default: ComponentType<RemoteRecentOrdersProps> };
    return loadRemoteComponent(
      `${origins.home}/components/fulfillment/orders-table.mjs`
    ) as Promise<ModuleType>;
  });

const RecentOrdersLoading = () => (
  <Panel kind="primary" css={[wrapper, loadingStyles, centeredContent]}>
    <Spinner />
  </Panel>
);

const RecentOrdersWrapper = ({
  reload,
  children,
}: {
  reload: boolean;
  children: any;
}) => (reload ? <RecentOrdersLoading /> : <div css={wrapper}>{children}</div>);

export type RecentOrdersProps = {
  onError: (err: Error) => void;
  updateUrlWithFilterData?: ((data: FilterData) => void) | undefined;
  queryParams?: FilterData | undefined;
  setShareError?: (b: boolean) => void;
  isTeamView?: boolean;
};

const RecentOrders = ({
  onError,
  queryParams,
  updateUrlWithFilterData,
  setShareError,
  isTeamView,
}: RecentOrdersProps) => {
  const [forceReload, setForceReload] = useState(false);

  const RemoteRecentOrdersComponent = useMemo(getLazyRecentOrdersComponent, [
    forceReload,
  ]);

  const tableConfig = {
    showCopyOrderBtn: true,
    ordersPerPage: 3,
    showSearchBox: true,
    showDownloadBtn: true,
    showStatusFilter: true,
    showViewAllOrdersLink: true,
    usePlainDivContainer: true,
    orderColumns: [
      OrderColumn.ORDER_NUMBER,
      OrderColumn.QUOTE_NUMBER,
      OrderColumn.ORDERED_ON,
      OrderColumn.DUE_DATE,
      OrderColumn.ORDER_STATUS,
      OrderColumn.ORDER_TOTAL,
    ],
  };

  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <RecentOrdersReload
          handleReload={() => {
            setForceReload(true);
            resetErrorBoundary?.();
            setTimeout(() => {
              setForceReload(false);
            }, 1000);
          }}
        />
      )}
      onError={err => {
        onError(err);
      }}
    >
      <RecentOrdersWrapper reload={forceReload}>
        <Suspense fallback={<RecentOrdersLoading />}>
          <RemoteRecentOrdersComponent
            apiRoot={origins.api}
            wwwRoot={origins.home}
            getRoot={origins.webapp}
            tableConfig={tableConfig}
            queryParams={queryParams}
            updateUrlWithFilterData={updateUrlWithFilterData}
            isTeamView={isTeamView}
            setErrorMessage={setShareError}
          />
        </Suspense>
      </RecentOrdersWrapper>
    </ErrorBoundary>
  );
};

export default RecentOrders;
